import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { constants, instances } from '../store';
gsap.registerPlugin(CustomEase);
CustomEase.create('in-out-smooth', 'M0,0 C0.8,0 0.2,1 1,1');

class ParallaxSwiper {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.swiper_product');
    this.dom.container = this.dom.el.querySelector('.swiper-wrapper');
    this.dom.items = this.dom.el.querySelectorAll('.swiper-slide');
    this.dom.images = this.dom.el.querySelectorAll('.box_card_thumb'); // box_card_thumb_img

    this.state = {
      open: false,
      scrollEnabled: false,
      progress: 0,
    };
  }
  
  setCache() {
    this.items = [];
    [...this.dom.items].forEach((el) => {
      const bounds = el.getBoundingClientRect();

      this.items.push({
        img: el.querySelector('img'),
        bounds,
        imgWidth: el.querySelector('img').getBoundingClientRect().width,
        x: 0,
      });
    });
  }

  render = () => {
    if (constants.isDevice) return;

    const scrollLast = Math.abs(instances.scroll.state.last);

    this.items.forEach((item) => {
      const { bounds } = item;
      const inView = scrollLast + window.innerWidth >= bounds.left && scrollLast < bounds.right;

      if (inView) {
        const max = bounds.right;
        const space = item.imgWidth - bounds.width;
        const percentage = ((window.innerWidth - max) / (window.innerWidth - bounds.width));
        item.x = -space + (percentage * space);
        item.img.style.transform = `translate3d(${item.x}px, 0, 0)`;
      }
    });
  };

  handleResize = () => {
    this.setCache();
  };

  addListeners() {
    window.addEventListener('resize', this.handleResize);
  }

  init() {
    gsap.ticker.add(this.render);
    this.setCache();
    this.addListeners();
  }
}

export default ParallaxSwiper;