/* =============================================================
 * import
 * ========================================================== */
import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import LocomotiveScroll from 'locomotive-scroll';
import {mediaQuery} from "./common";

gsap.registerPlugin(ScrollTrigger);

// Locomotive Scroll と GSAPの併用設定
const scrollContainer = ".vs-pax-container";
export const locoScroll = new LocomotiveScroll({
  el: document.querySelector(scrollContainer),
  smooth: true,
  lerp: .1,
});

/* Locomotive Scrollを解除する場合は、以下を調整すること
 * smoothScroll()
 * toTop()
 * scrollAdjuster()
 */

/* =============================================================
 * function
 * ========================================================== */

export function parallax() {
  locoScroll.on("scroll", ScrollTrigger.update);
  ScrollTrigger.scrollerProxy(scrollContainer, {
    scrollTop(value) {
      return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
    },
    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
    pinType: document.querySelector(scrollContainer).style.transform ? "transform" : "fixed",
  });

  gsap.utils.toArray('.vs-pax, ._l').forEach(elm => {
    let y = 0;
    let x = 0;
    let distance = elm.getAttribute('data-vs-pax-size') || -50;    // SP時の動きの大きさ
    const distanceSp = elm.getAttribute('data-vs-pax-size-sp') || distance;    // 動きの大きさ
    const direction = elm.getAttribute('data-vs-pax-dir') || 'y'; // 動きの方向（X：横、Y：縦）
    const scrub = elm.getAttribute('data-vs-pax-scrub') || 0.5;   // 動きの粒度（アニメーションの大きさ、慣性の大きさ）

    if (window.getComputedStyle(elm).getPropertyValue('display') === 'inline') {
      elm.style.display = 'inline-block';
    }

    if (mediaQuery() === 'sp') {
      distance = distanceSp;
    }

    if (direction === 'y') {
      y = distance;
    } else {
      x = distance;
    }

    gsap.set(elm, {
      x: -x,
      y: -y,
    });

    gsap.to(elm, {
      x: x,
      y: y,
      scrollTrigger: {
        trigger: elm,
        start: 'top bottom', // top-=100 bottom
        end: 'bottom top',
        scrub: scrub,
        scroller: scrollContainer,
        invalidateOnRefresh: true, // update on resize
        // once: true,
        // markers: true,
        // onEnter: () => elm.classList.add("js-vs-enter"),
      },
    });
  });

  ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
  ScrollTrigger.refresh();
}
