/* =============================================================
 * import
 * ========================================================== */
import Swiper from 'swiper/bundle';
import ParallaxSwiper from '../modules/parallaxSwiper';
import Scroll from '../utils/scroll';
import {instances} from '../store';
import bowser from 'bowser';
import {gsap} from "gsap/gsap-core";

const browser = bowser.getParser(window.navigator.userAgent);


/* =============================================================
 * function
 * ========================================================== */


export default function exe() {
  instances.scroll = new Scroll();
  instances.scroll.init();
  instances.parallaxSwiper = new ParallaxSwiper();
  instances.parallaxSwiper.init();
  columnSwiper();
  productSwiper();
  kvMouseStoker();

  /*
  window.addEventListener('resize', function() {
    columnSwiper();
    productSwiper();
    kvMouseStoker();
  });
  */
}

export function columnSwiper() {

  const swiper = new Swiper('.swiper', { // eslint-disable-line @typescript-eslint/no-unused-vars
    // modules: [Navigation, Autoplay, Scrollbar],
    slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    speed: 1200, // スライドアニメーションのスピード（ミリ秒）

    // cssMode: true,              // true: CSS Scroll Snap APIが適用
    // mousewheel: true,           // true: マウスホイールでの操作を許可
    // keyboard: true,             // true: キーボードでの操作を許可
    grabCursor: true,           // PCでマウスカーソルを「掴む」マークにする
    watchSlidesProgress: true,  // スライドの進行状況を監視する

    /*
    autoplay: { // 自動再生させる
      delay: 3000, // 次のスライドに切り替わるまでの時間（ミリ秒）
      disableOnInteraction: true, // ユーザーが操作しても自動再生を止めない
      waitForTransition: true, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
    },
    loop: true,  // 無限ループさせる
    loopAdditionalSlides: 1, // 無限ループさせる場合に複製するスライド数
    */

    freeMode: {
      enabled: true, // true：自由にドラッグ（スワイプ）できるようにする。スライド位置がスナップしなくなる
      momentum: true, // false：ドラッグ（スワイプ）した後の慣性スクロールをオフにする
      momentumRatio: .8, // 数字が大きいほど惰性スクロールの距離が伸びる（freeMode: trueとfreeModeMomentum:trueが前提）
      modeMomentumVelocityRatio: .2, // 数字が大きいほど惰性スクロールのスピードが速くなる（freeMode: trueとfreeModeMomentum:trueが前提）
      sticky: false,  // true:スナップを有効にする
    },

    preventClicks: true, // true: スワイプ中の不要なクリックを無効化
    preventClicksPropagation: true, // true: スワイプ中の不要なクリックイベントを無効化

    navigation: {
      nextEl: '.swiper-button-next', // 「次へ」ボタン要素のクラス
      prevEl: '.swiper-button-prev', // 「前へ」ボタン要素のクラス
    },

    scrollbar: {
      el: '.swiper-scrollbar', // スクロールバー要素のクラス
      draggable: true,
    },
  });
}

export function productSwiper() {
  const speed = 1200;
  const swiper = new Swiper('.swiper_product', { // eslint-disable-line @typescript-eslint/no-unused-vars
    slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    speed: speed, // スライドアニメーションのスピード（ミリ秒）

    // cssMode: true,              // true: CSS Scroll Snap APIが適用
    // mousewheel: true,           // true: マウスホイールでの操作を許可
    grabCursor: true,           // PCでマウスカーソルを「掴む」マークにする
    watchSlidesProgress: true,  // スライドの進行状況を監視する

    freeMode: {
      enabled: true, // true：自由にドラッグ（スワイプ）できるようにする。スライド位置がスナップしなくなる
      momentum: true, // false：ドラッグ（スワイプ）した後の慣性スクロールをオフにする
      momentumRatio: .8, // 数字が大きいほど惰性スクロールの距離が伸びる（freeMode: trueとfreeModeMomentum:trueが前提）
      modeMomentumVelocityRatio: .2, // 数字が大きいほど惰性スクロールのスピードが速くなる（freeMode: trueとfreeModeMomentum:trueが前提）
      sticky: false,  // true:スナップを有効にする
    },

    preventClicks: true, // true: スワイプ中の不要なクリックを無効化
    preventClicksPropagation: true, // true: スワイプ中の不要なクリックイベントを無効化

    navigation: {
      nextEl: '.swiper-button-next', // 「次へ」ボタン要素のクラス
      prevEl: '.swiper-button-prev', // 「前へ」ボタン要素のクラス
    },

    scrollbar: {
      el: '.swiper-scrollbar', // スクロールバー要素のクラス
      draggable: true,
    },

    on: {
      progress: () => {
        instances.parallaxSwiper.handleResize();
      },
      transitionStart: () => {
        let i = 0;
        const timer = 100;
        const limit = speed / timer;
        (function loop() {
          i++;
          if (i < limit) {
            instances.parallaxSwiper.handleResize();
            setTimeout(loop, timer);
          }
        })();
      },
    },
  });

}

export function kvMouseStoker() {

  const wrap = document.querySelector('#kv'); // target

  if (wrap) {
    const target1 = wrap.querySelector('.kv_bg .sakura_pc.sakura_scale');
    const target2 = wrap.querySelector('.kv_bg .sakura_pc.sakura_normal');
    const target3 = wrap.querySelector('.kv_figure');
    const target3Inner = wrap.querySelector('.kv_figure_img');
    const target4 = wrap.querySelectorAll('.kv_figure_sub_item');
    const target4Inner = wrap.querySelectorAll('.kv_figure_sub_img');
    const target5 = wrap.querySelectorAll('.kv_headline');

    const w = window.innerWidth;              // マウス移動領域の横幅（通常はスクロールバーを除く描画領域）
    const h = window.innerHeight;             // マウス移動領域の高さ（通常はスクロールバーを除く描画領域）

    const mouse = {x: 0, y: 0};
    const ratio = {x: 0, y: 0};
    const rotate1 = {x: 0, y: 0};
    const rotate2 = {x: 0, y: 0};
    const translate1 = {x: 0, y: 0};
    const translate2 = {x: 0, y: 0};
    const translate3 = {x: 0, y: 0};
    const translate4 = {x: 0, y: 0};

    const deg = 25;                           // 最大傾斜（deg）
    const deg2 = 40;                          // 最大傾斜（deg）
    const px = 40;                            // 最大移動幅（px）
    const px2 = 60;                           // 最大移動幅（px）
    const px3 = 80;                           // 最大移動幅（px）
    const px4 = 20;                           // 最大移動幅（px）

    if (!browser.is("mobile") && !browser.is("tablet")) {


      wrap.addEventListener('mousemove', function(e) {

        mouse.x = e.clientX;    // マウス位置 - 横
        mouse.y = e.clientY;    // マウス位置 - 縦
        ratio.x = mouse.x / w;  // マウス移動率（横）（×100で%）
        ratio.y = mouse.y / h;  // マウス移動率（縦）（×100で%）
        move(ratio.x, ratio.y, target1, target2);

      });

      /*
      } else {

        window.addEventListener('deviceorientation', function(e) {

          target1 = wrap.querySelector('.kv_bg .sakura_sp.sakura_scale');
          target2 = wrap.querySelector('.kv_bg .sakura_sp.sakura_normal');

          mouse.x = e.gamma;       // マウス位置 - 横
          mouse.y = e.beta;        // マウス位置 - 縦
          ratio.x = mouse.x;       // デバイス傾斜率（横）（×100で%）
          ratio.y = mouse.y;       // デバイス傾斜率（縦）（×100で%）

          deg = .1;                // 最大傾斜（deg）
          deg2 = .1;               // 最大傾斜（deg）
          px = .1;                 // 最大移動幅（px）
          px2 = .2;                // 最大移動幅（px）
          px3 = .1;                // 最大移動幅（px）
          px4 = .2;                // 最大移動幅（px）

          move(ratio.x, ratio.y, target1, target2);

        });
      */
    }

    const move = (x, y, target1, target2) => {
      ratio.x = x;                              // 移動率（横）（×100で%）
      ratio.y = y;                              // 移動率（縦）（×100で%）
      rotate1.x = (ratio.y * deg * 2) - deg;    // 回転率（横）
      rotate1.y = (ratio.x * deg * 2) - deg;    // 回転率（縦）
      rotate2.x = (ratio.y * deg2 * 2) - deg2;  // 回転率（横）
      rotate2.y = (ratio.x * deg2 * 2) - deg2;  // 回転率（縦）
      translate1.x = (ratio.x * px * 2) - px;   // 移動率（横）
      translate1.y = (ratio.y * px * 2) - px;   // 移動率（縦）
      translate2.x = (ratio.x * px2 * 2) - px2;  // 移動率（横）
      translate2.y = (ratio.y * px2 * 2) - px2;  // 移動率（縦）
      translate3.x = (ratio.x * px3 * 2) - px3;  // 移動率（横）
      translate3.y = (ratio.y * px3 * 2) - px3;  // 移動率（縦）
      translate4.x = (ratio.x * px4 * 2) - px4;  // 移動率（横）
      translate4.y = (ratio.y * px4 * 2) - px4;  // 移動率（縦）

      gsap.to(target1, {
        duration: 3,
        rotationY: rotate1.y,
        rotationX: rotate1.x,
        ease: "Power4.easeOut",
      });

      gsap.to(target2, {
        duration: 5,
        rotationY: rotate2.y,
        rotationX: rotate2.x,
        ease: "Power4.easeOut",
      });

      gsap.to(target3, {
        duration: 2,
        x: translate1.x,
        y: translate1.y,
        ease: "Power4.easeOut",
      });

      gsap.to(target3Inner, {
        duration: 4,
        x: translate2.x,
        y: translate2.y,
        ease: "Power4.easeOut",
      });

      gsap.to(target5, {
        duration: 1,
        x: translate4.x,
        y: translate4.y,
        ease: "Power4.easeOut",
      });

      target4.forEach(elem => {
        gsap.to(elem, {
          duration: 3,
          x: translate3.x,
          y: translate3.y,
          ease: "Power4.easeOut",
        });
      });

      target4Inner.forEach(elem => {
        gsap.to(elem, {
          duration: 2,
          x: translate3.x,
          y: translate3.y,
          ease: "Power4.easeOut",
        });
      });
    };
  }

}

