/* =============================================================
 * import
 * ========================================================== */
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import {gsap} from 'gsap';
import * as barbaOpt from './options';
import * as scrollObserver from '../modules/scrollObserver';
import * as routeCommon from '../route/common';
import top from '../route/top';
import news from '../route/news';
import story from '../route/story';
import column from '../route/column';
import contact from '../route/contact';
import * as transitionGlobal from './transition/global';
import {globalNavi} from '../modules/gnav';
import {smoothScroll} from '../modules/common';
import {CustomEase} from "gsap/CustomEase";
import {locoScroll, parallax} from "../modules/parallax";


/* =============================================================
 * variables
 * ========================================================== */

// アニメーション用の変数定義
const $body = document.body;
const domain = document.domain;
const $header = document.getElementById('header');

gsap.registerPlugin(CustomEase);


export default function barbaRun() {

  barba.use(barbaPrefetch);

  barba.init({
    debug: true,
    logLevel: 'debug',
    schema: {
      prefix: 'data-trans',
      wrapper: 'wrapper',
      container: 'container',
      namespace: 'namespace',
    },
    prevent: ({el, event}) => barbaOpt.customPreventCheck(el, event),
    // キャッシュを無効にするページ指定
    cacheIgnore: [
      '/contact/',
      '/contact/confirmation/',
      '/contact/thanks/',
    ],
    views: [
      {
        namespace: 'home',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          // routeTop.sliders();
          top();
        },
      },
      {
        namespace: 'news',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          news();
        },
      },
      {
        namespace: 'products',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          // products();
        },
      },
      {
        namespace: 'story',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          story();
        },
      },
      {
        namespace: 'column',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          column();
        },
      },
      {
        namespace: 'contact',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          contact();
        },
      },
    ],

    transitions: [
      // https://barba.js.org/docs/advanced/hooks/#Base-hooks
      {
        // 全ページ共通のトランジション
        name: 'global-transitions',
        // sync: true, //同時実行モード
        async leave(data) {
          const duration = 500;

          ttStart();
          barbaOpt.resetAnyFunc(data); // 各イベントトリガーのリセット
          transitionGlobal.leave(data, duration);

          await new Promise((resolve) => {
            return setTimeout(resolve, duration);
          });
        },
        async enter(data) {
          const duration = 10;

          globalNavi(data.next.url.path);
          transitionGlobal.enter(data);

          await new Promise((resolve) => {
            return setTimeout(resolve, duration);
          });
        },
        afterEnter() {
          scrollObserver.floating();
        },
        after(data) {
          const duration = 500;
          setTimeout(() => {
            ttEnd();
            transitionGlobal.after(data, duration);
          }, duration);
        },
      },
      /*
      {
        // 各ルールに応じた個別トランジションの設定
        name: 'custom-transitions',
        to: { // どのページへの遷移か
          namespace: [
            'company',
          ],
        },
        from: { // どのページからの遷移か
          namespace: [
            'home',
          ],
        },

        async leave(data) {
          // console.log(data);
        },

        async after(data) {
          // console.log(data);
        },
      },
      */
    ],

  });

  barba.hooks.beforeEnter(({next}) => {
    barbaOpt.replaceHeadTags(next);

    // barbaOpt.scrollAdjuster();
    // routeCommon.cursor();
    // routeCommon.cursorAnimation();
    if (domain === 'www.rokkakukan-sakurado.com') {
      const gaArg = {
        type: 'gtag',
        path: next.url.path,
        account: [
          'G-6HTZ1K52EZ',
          'UA-68028430-1',
        ],
      };
      barbaOpt.gaSend(gaArg);
    }
    // barbaOpt.snsBtnReload(next.url.href);
    routeCommon.before(next);
  });

  barba.hooks.afterEnter((data) => {
    // document.getElementById('cursor').classList.remove('active');
    routeCommon.after(data.next);
    barbaOpt.samePageNotReload();
  });

  barba.hooks.after((data) => {
    locoScroll.update(); // 慣性スクロールのリセット

    const trigger = data.trigger;
    let hash = '';

    // data-scroll-hashを持つ場合
    if (trigger !== '' && trigger !== 'back' && trigger !== 'forward') {
      const urlHash = trigger.data;
      const dataHash = trigger.dataset.scrollHash;
      if (dataHash !== undefined && dataHash !== null && dataHash !== '') {
        hash = dataHash;
      } else
      if(urlHash !== undefined && urlHash !== null && urlHash !== '') {
        hash = urlHash;
      }
    }

    smoothScroll(hash, 100); // スムーススクロールを実行
    parallax();
  });

  /*
   * Transitions
   *  beforeOnce, once, afterOnce, before,
   *  beforeLeave, leave, afterLeave, afterLeave,
   *  enter, afterEnter, after
   * Views
   *  beforeLeave, afterLeave, beforeEnter, afterEnter
   * */

}

// トランジション開始時の設定
function ttStart() {
  $body.classList.remove('loaded');
  $body.classList.add('loading');
  $header.classList.remove('scrolled');
  $header.style.pointerEvents = 'none';
}

// トランジション終了時の設定
function ttEnd() {
  $body.classList.remove('loading');
  $body.classList.add('loaded');
  $header.style.pointerEvents = 'auto';
}

